import { axios, store, removeLoginIfLogedOut, abortController } from './config/axios-config'

const getUserLocalOfertaData = async (ies) => {

    store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: false })
    try {
        const response = await axios.post('/user/data/listaLocalOfertaUsuario', ies, {
            cancelToken: abortController.token
        })
        store.dispatch({ type: 'GET_LIST_LOCAL_OFERTA_USUARIO', list: response.data })

        const responseTodosCursos = await axios.get('/user/data/listaLocalOfertaUsuarioTodosCursos', {
            cancelToken: abortController.token
        })
        store.dispatch({ type: 'GET_LIST_LOCAL_OFERTA_USUARIO_TODOS_CURSOS', list: responseTodosCursos.data })
        
    } catch (error) {
        removeLoginIfLogedOut(error)
        store.dispatch({ type: 'SET_LOADING', loading: false })
        store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: true })
    }


}

export default getUserLocalOfertaData
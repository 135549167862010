import { axios, store, removeLoginIfLogedOut, abortController } from './config/axios-config'

const getRelatorioDetalhado = async (anoSelecionado, codOcupacoes, estadoSelecionado, municipioSelecionado, cursoSelecionado, grauEscolaridadeSelecionado, area_Detalhada=false) => {

    try {
        const response = await axios.post('/backend/getRelatorioDetalhado', {
            ano: anoSelecionado,
            ocupacoes: codOcupacoes,
            uf: estadoSelecionado,
            municipio: municipioSelecionado,
            curso: cursoSelecionado,
            grauEscolaridade: grauEscolaridadeSelecionado,
            areaDetalhada: area_Detalhada
        },{
            cancelToken: abortController.token
        })
        
    
        store.dispatch({ type: 'SET_RELATORIO_DETALHADO', list: response.data })
        
    } catch (error) {
        removeLoginIfLogedOut(error)
        throw new Error('Erro de conexão.')
    }

}

export default getRelatorioDetalhado
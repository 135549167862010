import { axios, store, removeLoginIfLogedOut, abortController } from './config/axios-config'


const getEnadeIndicadores = async ({cursoSelecionado, instituicaoSelecionada, concorrenteSelecionado, anoSelecionado}) => {
   
        try {
            if (concorrenteSelecionado === 'all') {
                concorrenteSelecionado = await store.getState().concorrentes
            }
            const response = await axios.post('/backend/getEnadeIndicadores', {
                curso: cursoSelecionado,
                ies: instituicaoSelecionada,
                concorrente: concorrenteSelecionado,
                ano: anoSelecionado
            },{
                cancelToken: abortController.token
            })

            store.dispatch({ type: 'SET_ENADE_LISTA', list: response.data })
        } catch (error) {
            removeLoginIfLogedOut(error)
            throw new Error('Erro de conexão.')
        }

}

export default getEnadeIndicadores
export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const SELECT_COURSE = "SELECT_COURSE"
export const SELECT_LOCAL_OFERTA = "SELECT_LOCAL_OFERTA"
export const GET_LIST_COURSES = "GET_LIST_COURSES"
export const GET_LIST_USER_COURSES = "GET_LIST_USER_COURSES"
export const GET_LIST_LOCAL_OFERTA = "GET_LIST_LOCAL_OFERTA"
export const GET_LIST_LOCAL_OFERTA_USUARIO = "GET_LIST_LOCAL_OFERTA_USUARIO"
export const GET_LIST_LOCAL_OFERTA_USUARIO_TODOS_CURSOS = "GET_LIST_LOCAL_OFERTA_USUARIO_TODOS_CURSOS"
export const GET_LIST_OCUPATIONS = "GET_LIST_OCUPATIONS"
export const GET_LIST_VAGAS = "GET_LIST_VAGAS"
export const SET_LIST_REQUISICAO_VAGAS = "SET_LIST_REQUISICAO_VAGAS"
export const GET_CARGO_LOCAL = "GET_CARGO_LOCAL"
export const SET_CARGO_LOCAL = "SET_CARGO_LOCAL"
export const GET_VAGAS_DATA = "GET_VAGAS_DATA"
export const FILTER_COURSE = "FILTER_COURSE"
export const SELECT_IES = "SELECT_IES"
export const FILTER_IES = "FILTER_IES"
export const FILTER_OCUPATION = "FILTER_OCUPATION"

export const SET_OCUPATION = "SET_OCUPATION"
export const ADD_OCUPATION = "ADD_OCUPATION"
export const REMOVE_OCUPATION = "REMOVE_OCUPATION"
export const SAVE_OCUPATION = "SAVE_OCUPATION"
export const DISCARD_OCUPATION = "DISCARD_OCUPATION"

export const FILTER_OCUPATION_ADDED = "FILTER_OCUPATION_ADDED"
export const CHANGE_YEAR = "CHANGE_YEAR"
export const SET_ESTADO_SELECIONADO = "SET_ESTADO_SELECIONADO"
export const SET_ESTADO_SELECIONADO_CONCORRENTES = "SET_ESTADO_SELECIONADO_CONCORRENTES"
export const SET_MUNICIPIOS = "SET_MUNICIPIOS"
export const SET_MUNICIPIOS_CONCORRENTES = "SET_MUNICIPIOS_CONCORRENTES"
export const SET_MUNICIPIO_SELECIONADO = "SET_MUNICIPIO_SELECIONADO"
export const SET_MUNICIPIO_SELECIONADO_CONCORRENTES = "SET_MUNICIPIO_SELECIONADO_CONCORRENTES"
export const SET_OCUPACAO_SELECIONADA = "SET_OCUPACAO_SELECIONADA"
export const SET_CONCORRENTE_SELECIONADO = "SET_CONCORRENTE_SELECIONADO"
export const SET_GRAU_ESCOLARIDADE_SELECIONADO = "SET_GRAU_ESCOLARIDADE_SELECIONADO"

export const SET_INDICADORES = "SET_INDICADORES"
export const SET_LOADING = "SET_LOADING"
export const SET_PAGELOAD = "SET_PAGELOAD"

export const SET_IES_LIST = "SET_IES_LIST"
export const SET_USER_IES_LIST = "SET_USER_IES_LIST"
export const SET_IES_INDICADORES = "SET_IES_INDICADORES"

export const SET_DATA_VAGA_SELECIONADA = "SET_DATA_VAGA_SELECIONADA"
export const SET_ENADE_LISTA = "SET_ENADE_LISTA"

export const SAVE_COST = "SAVE_COST"
export const SET_ROI = "SET_ROI"

export const SET_RELATORIO_DETALHADO = "SET_RELATORIO_DETALHADO"
export const SET_RELATORIO_DETALHADO_IES = "SET_RELATORIO_DETALHADO_IES"
export const SET_RANKING_AUTOMACAO = "SET_RANKING_AUTOMACAO"

export const SET_WIZARD = "SET_WIZARD"

export const SET_LOGIN = "SET_LOGIN"
export const SET_ANO_BASE = "SET_ANO_BASE"

export const SAVE_REDUX = "SAVE_REDUX"
export const LOAD_REDUX = "LOAD_REDUX"

export const USER_IS_EDITING = "USER_IS_EDITING"

export const SET_LISTA_CONCORRENTES = "SET_LISTA_CONCORRENTES"
export const SET_CONCORRENTES = "SET_CONCORRENTES"

export const RESTORE_REDUX = "RESTORE_REDUX"

export const SET_TIPO_DASHBOARD = "SET_TIPO_DASHBOARD"

export const SET_STATUS_ERRO_CONEXAO = "SET_STATUS_ERRO_CONEXAO"

export const SET_CONCORRENTES_INDICADORES = "SET_CONCORRENTES_INDICADORES"

export const SET_LOAD_DATA_DASHBOARD_EMPREGABILIDADE = "SET_LOAD_DATA_DASHBOARD_EMPREGABILIDADE"
export const SET_LOAD_DATA_DASHBOARD_INTELIGENCIA_COMPETITIVA = "SET_LOAD_DATA_DASHBOARD_INTELIGENCIA_COMPETITIVA"
export const SET_LOAD_DATA_DASHBOARD_RELATORIO_DETALHADO = "SET_LOAD_DATA_DASHBOARD_RELATORIO_DETALHADO"
export const SET_LOAD_DATA_RESUMO = "SET_LOAD_DATA_RESUMO"

export const SET_USER_SAVED_STATES = "SET_USER_SAVED_STATES"

export const SET_EXIBIR_BOTAO_VOLTAR_GERENCIAR_CURSOS = "SET_EXIBIR_BOTAO_VOLTAR_GERENCIAR_CURSOS"

export const SET_NAMES_COLLECTIONS = "SET_NAMES_COLLECTIONS"

export const SET_USUARIOS = "SET_USUARIOS"
export const SET_AREA_DETALHADA_SELECIONADA = "SET_AREA_DETALHADA_SELECIONADA"
export const SET_PERFIS = "SET_PERFIS"

export const SET_USUARIO_SELECIONADO = "SET_USUARIO_SELECIONADO"
export const SET_IES_USUARIO_SELECIONADA = "SET_IES_USUARIO_SELECIONADA"
export const SET_LOCAL_OFERTA_USUARIO_SELECIONADO = "SET_LOCAL_OFERTA_USUARIO_SELECIONADO"
export const SET_CURSO_USUARIO_SELECIONADO = "SET_CURSO_USUARIO_SELECIONADO"

export const CHECK_USER_SUPERADMIN = "CHECK_USER_SUPERADMIN"

export const SET_DATABASE_CREATE_UPDATE_SUCCESS_STATUS = "SET_DATABASE_CREATE_UPDATE_SUCCESS_STATUS"
export const SET_REQUISICAO_VAGAS_STATUS = "SET_REQUISICAO_VAGAS_STATUS"
export const SET_INFO_MUNICIPIOS = "SET_INFO_MUNICIPIOS"

export const SET_CANCELAR_WIZARD = "SET_CANCELAR_WIZARD"

export const SET_FILTER_SHOW = "SET_FILTER_SHOW"
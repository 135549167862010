import { axios, store, removeLoginIfLogedOut} from './config/axios-config'

const getVagas = async (listaOcupacoes, data = null, estado = null, municipio = null) => {

    store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: false })
    store.dispatch({ type: 'SET_LOADING', loading: true })
    try {
        let response = null
        response = await axios.post('/backend/retornaVagas',{listaOcupacoes, data, estado, municipio})
        store.dispatch({ type: 'GET_LIST_VAGAS', list: response.data })
        store.dispatch({ type: 'SET_LOADING', loading: false })
        
    } catch (error) {
        removeLoginIfLogedOut(error)
        store.dispatch({ type: 'SET_LOADING', loading: false })
        store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: true })
    }
}
export default getVagas
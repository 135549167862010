const indicatorsNames = {
    saldoEmprego: 'Saldo de Emprego',
    rankingSaldo: 'Ranking do Saldo de Emprego',
    egressosSaldo: 'Egressos / Saldo de Emprego',
    probAuto: 'Probabilidade de Automação',
    tipoOcupacao: 'Nível de Escolaridade da Ocupação',
    salarioMedio: 'Salário Médio',
    comparativoSalario: 'Comparativo de Salário',
    wagePremium: 'Wage Premium',
    nivelDocente: 'Nível do Corpo Docente (IQCD)',
    classificacaoCurso: 'Classificação do Curso (CPC)',
    ROI: 'Retorno Sobre Investimento (ROI)',
    tempoROI: 'Tempo de Payback',

}

export default indicatorsNames
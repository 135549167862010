import { axios, store, removeLoginIfLogedOut, abortController } from './config/axios-config'
import getRelatorioDetalhado from './getRelatorioDetalhado'
// import getIESIndicadores from './getIESIndicadores'
// import getIndicadoresROI from './getIndicadoresROI'
import { stopLoadingPage } from '../utils/LoadingPageController'

const getIndicadores = async ({anoSelecionado, codOcupacoes, estadoSelecionado, municipioSelecionado, cursoSelecionado, grauEscolaridadeSelecionado}, salvarIndicadores=true, area_Detalhada=false) => {

    if(anoSelecionado !== '' && codOcupacoes.length > 0 && estadoSelecionado !== ''){

        if(salvarIndicadores){
            const saveIndicadores = axios.put('/user/data/salvarIndicadores', {
                ocupacoes: codOcupacoes,
                curso: cursoSelecionado
            })
        }
            
        try {
            const response = await axios.post('/backend/getIndicadores', {
                ano: anoSelecionado,
                ocupacoes: codOcupacoes,
                uf: estadoSelecionado,
                municipio: municipioSelecionado,
                curso: cursoSelecionado,
                grauEscolaridade: grauEscolaridadeSelecionado,
                areaDetalhada: area_Detalhada
            },{
                cancelToken: abortController.token
            })

            store.dispatch({ type: 'SET_INDICADORES', list: response.data })

            return response.data
            
        } catch (error) {
            removeLoginIfLogedOut(error)
            throw new Error('Erro de conexão.')
        }
        

    }

}

export default getIndicadores
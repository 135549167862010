const stopLoadingPage = (startTime, dispatch, minTime=1000) => {
    const endTime = new Date().getTime();
    const finalTime = endTime - startTime;

    if(finalTime < minTime){
        setTimeout(() => {
            dispatch({ type: 'SET_PAGELOAD', load: false })
        }, minTime - finalTime); //run this after 3 seconds
    }else{
        dispatch({ type: 'SET_PAGELOAD', load: false })
    }

}

const startLoadingPage = (dispatch) => {
    dispatch({ type: 'SET_PAGELOAD', load: true })
    return new Date().getTime()
}

module.exports = {
    startLoadingPage,
    stopLoadingPage
}
 
import indicatorsNames from './indicatorsNames'

const getMenuItems = (course = "", ocupations = []) => {

    // if(course === ""){
    //     return {
    //         items: [
    //             {
    //                 id: 'curso',
    //                 title: 'Selecione um curso',
    //                 type: 'group',
    //                 icon: 'feather icon-home',
    //             }
    //         ]
    //     }
    // }
    
    return {
        items: [
            {
                id: 'navigation',
                title: 'Configurações',
                type: 'group',
                icon: 'icon-navigation',
                children: [
                    {
                        id: 'usuarios',
                        title: 'Gerenciar Usuários',
                        type: 'item',
                        url: '/gerenciarusuarios/',
                        action: ''
                    },{
                        id: 'gerenciarInstituicoes',
                        title: 'Gerenciar IES',
                        type: 'item',
                        url: '/gerenciaries/',
                        action: ''
                    },{
                        id: 'gerenciarLocaisOferta',
                        title: 'Gerenciar Locais de Oferta',
                        type: 'item',
                        url: '/gerenciarlocaisoferta/',
                        action: ''
                    },{
                        id: 'gerenciarCursos',
                        title: 'Gerenciar Cursos',
                        type: 'item',
                        url: '/gerenciarcursosusuario/',
                        action: ''
                    },{
                        id: 'logout',
                        title: 'LABORe IES',
                        type: 'item',
                        url: '',
                        action: 'logout',
                        breadcrumbs: false
                    }

                ]
            }
        ]
    }
}

export default getMenuItems
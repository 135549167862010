import config from './config';
import getMenuItems from './menuItems'


const initialState = {
    isOpen: [], //for active default menu
    isTrigger: [], //for active default menu, set blank for horizontal
    ...config,
    menu: getMenuItems(),
    isFullScreen: false, // static can't change
};

export default initialState
import { axios, store, removeLoginIfLogedOut, abortController } from './config/axios-config'

const getRelatorioDetalhadoIES = async (anoSelecionado, estadoSelecionado, municipioSelecionado, cursoSelecionado, instituicaoSelecionada, concorrente) => {
    
    try {
        const response = await axios.post('/backend/getRelatorioDetalhadoIES', {
            ano: anoSelecionado,
            uf: estadoSelecionado,
            municipio: municipioSelecionado,
            curso: cursoSelecionado,
            ies: instituicaoSelecionada,
            concorrente
        },{
            cancelToken: abortController.token
        })
        
        store.dispatch({ type: 'SET_RELATORIO_DETALHADO_IES', list: response.data })
        
    } catch (error) {
        removeLoginIfLogedOut(error)
        throw new Error('Erro de conexão.')
    }

}

export default getRelatorioDetalhadoIES
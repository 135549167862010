import { axios, store, removeLoginIfLogedOut, abortController } from './config/axios-config'
import getRelatorioDetalhadoIES from './getRelatorioDetalhadoIES'


const getIESIndicadores = async ({anoSelecionado, estadoSelecionado, municipioSelecionado, cursoSelecionado, instituicaoSelecionada, concorrente}) => {

    if(anoSelecionado !== '' && estadoSelecionado !== ''){
        
        try {
            const response = await axios.post('/backend/getIESIndicadores', {
                ano: anoSelecionado,
                uf: estadoSelecionado,
                municipio: municipioSelecionado,
                curso: cursoSelecionado,
                ies: instituicaoSelecionada,
                concorrente
            },{
                cancelToken: abortController.token
            })

            store.dispatch({ type: 'SET_IES_INDICADORES', list: response.data })
        } catch (error) {
            removeLoginIfLogedOut(error)
            throw new Error('Erro de conexão.')
        }
        
    }

}

export default getIESIndicadores
import { axios, store, removeLoginIfLogedOut, abortController } from './config/axios-config'

const getUserCourseDataAll = async (ies, localOferta) => {

    store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: false })
    try {
        const response = await axios.post('/user/data/listaCursosUsuario', {
            ies,
            localOferta
        },{
            cancelToken: abortController.token
        })
        store.dispatch({ type: 'GET_LIST_USER_COURSES', list: response.data })

        const responseTodosCursos = await axios.get('/user/data/listaLocalOfertaUsuarioTodosCursos', {
            cancelToken: abortController.token
        })
        store.dispatch({ type: 'GET_LIST_LOCAL_OFERTA_USUARIO_TODOS_CURSOS', list: responseTodosCursos.data })
        
    } catch (error) {
        console.log("Erro na getCourseDataAll.")
        removeLoginIfLogedOut(error)
        store.dispatch({ type: 'SET_LOADING', loading: false })
        store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: true })
    }


}

export default getUserCourseDataAll
import setSavedUserState from "../connection/setSavedUserState"
import filterStateToSave from "./filterStateToSave"

const saveState = async (state, databaseSave=true) => {


    try {
      state = filterStateToSave(state)
      const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);
      if(databaseSave) {
        setSavedUserState(state);
      }
    } catch (err) {
      console.log("Erro no saveState", err);
      // ignore write errors
    }
  };

const loadState = () => {
    try {
      const serializedState = localStorage.getItem('state');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  };

const removeState = () => {
  try {
    localStorage.removeItem('state');
  } catch (err) {
    return undefined;
  }
};

  export default {saveState, loadState, removeState}
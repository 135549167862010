import { store, axios, removeLoginIfLogedOut } from './config/axios-config'
import filterStateToSave from '../store/filterStateToSave'

const setSavedUserState = async (state, update_time=false) => {

    let stateToSave = filterStateToSave(state)
    if(update_time){
        const userSavedStates = store.getState().userSavedStates

        let inList = false

        // Remove o curso que está sendo atualizado
        userSavedStates.forEach((userSavedState, index) => {
            if(userSavedState.state.cursoSelecionado.CO_CURSO_LABORE_IES === stateToSave.cursoSelecionado.CO_CURSO_LABORE_IES){
                userSavedStates.splice(index, 1)
                inList = true
            }
        })

        // Se o curso não estiver na lista, remove o último
        if(!inList){
            userSavedStates.pop()
        }

        userSavedStates.unshift({state: stateToSave})

        setTimeout(() =>{store.dispatch({ type: 'SET_USER_SAVED_STATES', states: userSavedStates })}, 0)

        stateToSave = {
            cursoSelecionado: {
                CO_CURSO_LABORE_IES: stateToSave.cursoSelecionado.CO_CURSO_LABORE_IES
            },
            update_time
        }
    }
    try {
        const response = await axios.post('/user/data/savedUserState', stateToSave)

        if(!update_time)
            setTimeout(() =>{store.dispatch({ type: 'SET_USER_SAVED_STATES', states: response.data })}, 0)
    } 
    catch(error) {
        removeLoginIfLogedOut(error)
        return true
    }

}



export default setSavedUserState
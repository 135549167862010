import axios from 'axios'
import { local } from 'd3-selection'

import store from '../../store'

const baseURL = process.env.REACT_APP_LABORE_GATEWAY_URL

axios.defaults.baseURL = baseURL

const token = sessionStorage.getItem('token') || localStorage.getItem('token')

axios.defaults.headers = {
    Authorization: `Bearer ${token}`
}

const abortController = axios.CancelToken.source()

if(store) store.dispatch({ type: 'SET_LOGIN', token: token ? true:false })

// Função que remove as sessions caso o usuário não esteja com token válido
const removeLoginIfLogedOut = (error) => {
    if(error.response && error.response.status === 401){
        store.dispatch({ type: 'SET_LOGIN', token: false })
        sessionStorage.removeItem('token')
        localStorage.removeItem('state')
        localStorage.removeItem('token')
    }
}

export { axios, store, removeLoginIfLogedOut, abortController }
import { axios, store, removeLoginIfLogedOut, abortController } from './config/axios-config'

const getIESDataAll = async () => {

    store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: false })
    try {

        let responseIES = await axios.get('/backend/listaIES',{
            cancelToken: abortController.token
        })

        let responseUserIES = await axios.get('/user/data/listaUserIES',{
            cancelToken: abortController.token
        })

        let response = responseIES.data.concat(responseUserIES.data)

        console.log(response)

        store.dispatch({ type: 'SET_USER_IES_LIST', list: responseUserIES.data  })
        store.dispatch({ type: 'SET_IES_LIST', list: response.sort((a, b) => a.NO_IES.localeCompare(b.NO_IES)) })
    } catch (error) {
        removeLoginIfLogedOut(error)
        store.dispatch({ type: 'SET_LOADING', loading: false })
        store.dispatch({ type: 'SET_STATUS_ERRO_CONEXAO', value: true })
    }


}

export default getIESDataAll

import { axios, store, removeLoginIfLogedOut, abortController } from './config/axios-config'

const getIndicadoresROI = async ({salarioMedio, salarioEnsinoMedio, custo, salarioGraduacao, periodo}) => {

        try {
            const response = await axios.post('/backend/getIndicadoresROI', {
                salarioMedio,
                salarioEnsinoMedio,
                custo,
                salarioGraduacao,
                periodo
            },{
                cancelToken: abortController.token
            })

            store.dispatch({ type: 'SET_ROI', list: response.data.indicadorROI })

        } catch (error) {
            removeLoginIfLogedOut(error)
            throw new Error('Erro de conexão.')
        }

}

export default getIndicadoresROI
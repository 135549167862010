const filterStateToSave = (state) => {
    return {
        cursoSelecionado: state.cursoSelecionado,
        concorrentes: state.concorrentes,
        listaCursos: state.listaCursos,
        listaCursosFiltrado: state.listaCursosFiltrado,
        ocupacoes: state.ocupacoes,
        codOcupacoes: state.codOcupacoes,
        listaOcupacoes: state.listaOcupacoes,
        listaOcupacoesFiltrado: state.listaOcupacoesFiltrado,
        ocupacoesFiltrado: state.ocupacoesFiltrado,
        ocupacoesSaved: state.ocupacoesSaved,
        instituicaoSelecionada: state.instituicaoSelecionada,
        listaInstituicoes: state.listaInstituicoes,
        listaInstituicoesFiltrado: state.listaInstituicoesFiltrado,
        localOfertaSelecionado: state.localOfertaSelecionado,
        listaLocalOferta: state.listaLocalOferta,
        anoSelecionado: state.anoSelecionado,
        estadoSelecionado: state.estadoSelecionado,
        estadoSelecionadoCombo: state.estadoSelecionadoCombo,
        estadoSelecionadoConcorrentes: state.estadoSelecionadoConcorrentes,
        estadoSelecionadoComboConcorrentes: state.estadoSelecionadoComboConcorrentes,
        listaMunicipios: state.listaMunicipios,
        listaMunicipiosConcorrentes: state.listaMunicipiosConcorrentes,
        municipioSelecionado: state.municipioSelecionado,
        municipioSelecionadoCombo: state.municipioSelecionadoCombo,
        municipioSelecionadoConcorrentes: state.municipioSelecionadoConcorrentes,
        municipioSelecionadoComboConcorrentes: state.municipioSelecionadoComboConcorrentes,
        ocupacaoSelecionada: state.ocupacaoSelecionada,
        ocupacaoSelecionadaCombo: state.ocupacaoSelecionadaCombo,
        concorrenteSelecionado: state.concorrenteSelecionado,
        concorrenteSelecionadoCombo: state.concorrenteSelecionadoCombo,
        custoTotal: state.custoTotal,
        relatorioDetalhado: state.relatorioDetalhado,
        tipoDashboard: state.tipoDashboard,
        cargoLocalSelecionadoCombo: state.cargoLocalSelecionadoCombo,
        areaDetalhadaSelecionadoCombo: state.areaDetalhadaSelecionadoCombo,
        dataSelecionadoCombo: state.dataSelecionadoCombo,
    }
}

export default filterStateToSave